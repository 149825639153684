<template>
  <div>
    <b-form-group
      :label="label"
      :label-for="idSelect.toString()"
      :style="!label ? 'margin-bottom:0' : ''"
    >
      <validation-provider
        #default="{ errors }"
        ref="validacao"
        :name="label.toLowerCase()"
        :rules="{required: require_ }"
      >
        <v-select
          :id="idSelect"
          :ref="`${name}Select`"
          v-model="value_"
          v-b-tooltip.hover
          :title="titlePopover"
          :options="itens"
          :class="selectBaseEstilo"
          :label="labelSelect"
          :clearable="!require_"
          :multiple="multiple"
          :disabled="disabled"
          :placeholder="placeholder"
          @close="onTouch"
          @open="carregarItens"
          @search-change="carregarItensFiltrados"
          @input="enviarParaPai"
        />
        <small class="text-danger">{{ mostrarErro(errors[0]) }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<style lang="scss">
.is-select-invalid > .vs__dropdown-toggle {
  border-color: #ea5455 !important;
  background-repeat: no-repeat !important;
  background-size: calc(0.725em + 0.438rem) calc(0.725em + 0.438rem) !important;
}

.select-base-clearable .vs__dropdown-option:first-child {
  padding:1rem;
}
</style>

<script>
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */

import {
  required,
} from '@validations'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  name: 'SelectBase',
  components: {
    vSelect,
    ValidationProvider,
  },
  props: {
    name: {
      type: String,
      default: 'selectBase',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    require: {
      type: Boolean,
      default: false,
    },
    invalido: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => { },
    },
    label: {
      type: String,
      default: '',
    },
    carregar: {
      type: Function,
      required: true,
    },
    labelSelect: {
      type: String,
      default: '',
    },
    filtros: {
      type: Array,
      default: () => [],
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    filtroAdicional: {
      type: Function,
      default: () => null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    titlePopover: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      itens: [],
      valueAttr: null,
      foiAcessado: false,
      primeiroAcesso: true,
      multipleChanged: false,
      itensCarregados: [],
      idSelect: this._uid,
    }
  },
  computed: {
    value_: {
      get() {
        if (this.require_) {
          return this.valueAttr ? this.valueAttr : this.value
        }
        return this.valueAttr
      },
      set(newValue) {
        this.valueAttr = newValue
        return this.valueAttr
      },
    },
    isInvalido() {
      return this.invalido && this.foiAcessado
    },
    require_: {
      get() {
        return this.require
      },
    },
    selectBaseEstilo() {
      let estilo = this.invalido ? 'is-select-invalid' : ''

      if (this.clearable) {
        estilo += ' select-base-clearable'
      }

      return estilo
    },
  },
  watch: {
    multiple(val, oldVal) {
      if (val !== oldVal) {
        this.multipleChanged = true
      }
    },
    value(val, oldVal) {
      if (val !== oldVal) {
        this.value_ = val
      }
    },
  },
  created() {
    this.valueAttr = this.value
  },
  methods: {
    carregarItens() {
      if (this.primeiroAcesso) {
        this.carregar(this.filtros)
          .then(payload => {
            this.itensCarregados = [...payload.data]
            this.filtraItens(payload.data)
            this.primeiroAcesso = false
          })
          .catch(err => {
            this.$errorFormated(err.response)
            throw err
          })
      } else {
        this.itens = [...this.itensCarregados]
        this.filtraItens(this.itens)
      }
    },
    carregarItensFiltrados(digitado) {
      const filtros = this.filtros.filter(fil => fil.soletrado)
      filtros.forEach(each => {
        each.valor = digitado
      })
      this.carregar(this.filtros)
        .then(payload => {
          this.itensCarregados = [...payload.data]
          this.filtraItens(payload.data)
        })
        .catch(err => {
          this.$errorFormated(err.response)
          throw err
        })
    },
    propagarParaFilho() {
      this.$refs[`${this.name}Select`].focus()
    },
    enviarParaPai() {
      if (this.value_ === null) {
        this.$emit('input', null)
      } else {
        if (this.multipleChanged) {
          if (this.multiple) {
            this.value_ = this.value
          } else {
            this.value_ = []
          }
          this.multipleChanged = false
        }

        this.$emit('select', !this.value_ ? null : this.value_)
        this.$emit('input', !this.value_ ? null : this.value_)
      }
    },
    onTouch() {
      this.foiAcessado = true
    },
    limpar() {
      this.value_ = []
    },
    validar() {
      return this.$refs.validacao.validate()
    },
    adicionaOpcaoVazia() {
      this.itens.unshift({ value: null, text: '' })
    },
    filtraItens(itensCarregados) {
      const itensFiltroAdicional = this.filtroAdicional(itensCarregados)
      this.itens = itensFiltroAdicional !== null ? itensFiltroAdicional : itensCarregados

      if (this.clearable && this.itens.length > 0) {
        this.adicionaOpcaoVazia()
      }
    },
    mostrarErro(erro) {
      if (erro !== undefined && erro.includes('field is required')) {
        return `O campo ${this.label.toLowerCase()} é obrigatório`
      }
      return erro
    },
  },
}
</script>
